<template>
  <div class="grid">
    <div class="col-7">

      <div class="card">
        <TreniWidget :header="t('label.widget.treni.da-pianificare')" status="planned"/>
      </div>

      <div class="mb-4"/>
      <div class="card">
        <TreniWidget :header="t('label.widget.treni.in-corso')" status="ongoing"/>
      </div>

      <div class="mb-4"/>

      <div class="card">
        <TreniWidget :header="t('label.widget.treni.eseguiti')" status="executed"/>
      </div>

    </div>
    <div class="col-5">
      <SoggettoRichiesteTable/>
      <ScadenzeCarriWidget/>
      <UltimiContrattiWidget :num-items="5" v-if="checkPermission([Roles.EXECUTION_MANAGER])"/>
    </div>
  </div>

  <TimelineStateDiagramDialog/>

</template>

<script setup>
import ScadenzeCarriWidget from "@/components/dashboard/carro/ScadenzeCarriWidget";
import UltimiContrattiWidget from "@/components/dashboard/UltimiContrattiWidget";
import Roles from "@/components/common/constants/Roles";
import TreniWidget from "@/components/dashboard/treno/TreniWidget";
import {useI18n} from "vue-i18n";
import useAcl from "@/composable/useAcl";
import TimelineStateDiagramDialog from "@/components/common/timeline/TimelineStateDiagramDialog";
import SoggettoRichiesteTable from "@/components/anagrafica/soggetto/richieste/SoggettoRichiesteTable";

const {t} = useI18n();
const {checkPermission} = useAcl();

</script>

<style scoped>

</style>
