<template>

  <!-- viaggi -->
  <DataTable v-model:value="viaggi"
             :paginator="true"
             :rows="datatableConfig.rows"
             :rowsPerPageOptions="datatableConfig.rowsPerPageOptions"
             :paginatorTemplate="datatableConfig.paginatorTemplate"
             :currentPageReportTemplate="datatableConfig.currentPageReportTemplate"
             responsiveLayout="scroll">

    <!-- header -->
    <template #header>
      <div class="table-header flex flex-column md:flex-row md:justify-content-between">
        <h4 class="m-0 uppercase">{{ header }}</h4>
      </div>
    </template>

    <!-- numero viaggio -->
    <Column field="codice" :header="t('label.viaggio.codice')"/>

    <!-- contratto brokeraggio -->
    <Column field="contrattoBrokeraggio" :header="t('label.contratto.brokerage')"/>

    <!-- periodoConsegna -->
    <Column field="periodoConsegna" :header="t('label.contratto.consegna')">
      <template #body="slotProps">
        {{ periodoConsegna(slotProps.data.dataInizioConsegna, slotProps.data.dataLimiteConsegna) }}
      </template>
    </Column>

    <!-- stazioneCarico -->
    <Column field="stazioneCarico" :header="t('label.contratto.stazioni-ferrovia.stazione-carico')"/>

    <!-- etd (viaggio pieno) -->
    <Column :header="t('label.viaggio.etd')">
      <template #body="slotProps">
        {{ dateTimeFormat(slotProps.data.etdViaggioPieno) }}
      </template>
    </Column>

    <!-- stazioneScarico -->
    <Column field="stazioneScarico" :header="t('label.contratto.stazioni-ferrovia.stazione-scarico')"/>

    <!-- eta (viaggio pieno) -->
    <Column :header="t('label.viaggio.eta')">
      <template #body="slotProps">
        {{ dateTimeFormat(slotProps.data.etaViaggioPieno) }}
      </template>
    </Column>

    <!-- actions -->
    <Column headerStyle="width: 8em" headerClass="p-text-center" bodyClass="p-text-center">
      <template #body="slotProps">

        <Button icon="pi pi-chart-bar"
                class="p-button-warning mr-2"
                @click="showDiagrammaViaggioDialog(slotProps.data)"
                v-tooltip.top="t('action.flow-diagram')"/>

      </template>
    </Column>

  </DataTable>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import useDateTime from "@/composable/useDateTime";
import contrattoRestService from "@/services/ContrattoRestService";
import workflowRestService from "@/services/WorkflowRestService";
import EventService from "@/services/EventService";
import {SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG} from "@/components/common/constants/Events";
import viaggioRestService from "@/services/ViaggioRestService";
import {isNil} from "lodash";
import {useStore} from "vuex";


const props = defineProps({
  header: {type: String, required: true},
  status: {type: String, required: true}
});
const {t} = useI18n();
const store = useStore();
const {dateTimeFormat, dateFormat} = useDateTime();

const datatableConfig = store.getters["config/datatable"];
const viaggi = ref([]);


onMounted(async () => {
  await loadViaggi();
});

const loadViaggi = async () => {

  if ('planned' === props.status) {
    viaggi.value = (await viaggioRestService.findDaPianificare()).data;
  } else if ('ongoing' === props.status) {
    viaggi.value = (await viaggioRestService.findInCorso()).data;
  } else if ('executed' === props.status) {
    viaggi.value = (await viaggioRestService.findEseguiti()).data;
  }

}

const periodoConsegna = (dataInizioConsegna, dataLimiteConsegna) => {
  dataInizioConsegna = !isNil(dataInizioConsegna) ? dateFormat(dataInizioConsegna) : "";
  dataLimiteConsegna = !isNil(dataLimiteConsegna) ? dateFormat(dataLimiteConsegna) : "";
  return dataInizioConsegna + " - " + dataLimiteConsegna;
}

const showDiagrammaViaggioDialog = async (it) => {
  let contratto = (await contrattoRestService.findById(it.contrattoId)).data;
  contratto.workflow = await workflowRestService.findByContrattoId(it.contrattoId);
  const workflow = JSON.parse(contratto.workflow.context);
  EventService.emit(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, {contratto, workflow});
}

</script>

<style scoped>

</style>
