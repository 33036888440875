<template>
  <div class="card schedule">
    <div class="card-header">
      <div class="card-title">
        <h4>{{toUppercase(t('label.widget.contratto.ultimi-contratti', {numItems: numItems}) )}}</h4>
      </div>
      <div class="subtitle">
        <router-link to="/contratti">{{ t('action.go-to-contratti-list') }}</router-link>
      </div>
    </div>

    <ul>
      <li v-for="contract in lastContracts" v-bind:key="contract.id">
        <div class="schedule-header">
          <h6>{{ contract.codice }}</h6>
          <span class="p-mt-2">
            <Button
               icon="pi pi-chart-bar"
               class="p-button-warning p-mr-2"
               v-tooltip.top="t('action.flow-diagram')"
               @click="showWorkflowDiagramDialog(contract)"
           />
          </span>
        </div>
        <span>{{dateTimeFormat(contract.datains)}}</span>
        <span>{{ truncate(contract.dettaglioMerce, 100) }}</span>
      </li>
    </ul>

    <Button type="button"
            :label="t('action.new-brokerage-contract')"
            v-if="checkPermission([Roles.BROKER])"
            icon="pi pi-plus"
            class="p-button-outlined"
            style="width: 100%"
            @click="showContrattoWizard"/>

    <TimelineStateDiagramDialog/>
    <ContrattoDataDialog/>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import EventService from "@/services/EventService";
import useText from "@/composable/useText";
import {SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG} from "@/components/common/constants/Events";
import TimelineStateDiagramDialog from "@/components/common/timeline/TimelineStateDiagramDialog";
import {useI18n} from "vue-i18n";
import useAcl from "@/composable/useAcl";
import Roles from "@/components/common/constants/Roles";
import {useRouter} from "vue-router";
import ContrattoDataDialog from "@/components/contratto/ContrattoDataDialog";
import useDateTime from "@/composable/useDateTime";

const props = defineProps({
  numItems: {required: true, type: Number}
});

const {t} = useI18n();
const store = useStore();
const {truncate} = useText();
const {checkPermission} = useAcl();
const {dateTimeFormat} = useDateTime();
const {toUppercase} = useText();
const router = useRouter();

const lastContracts = computed(() => store.getters['contratto/ultimiContratti'](props.numItems));

onMounted(() => {
  store.dispatch("contratto/loadAll");
});

function showWorkflowDiagramDialog(contratto) {
  const workflow = JSON.parse(contratto.workflow.context);
  EventService.emit(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, {contratto, viaggio: null, workflow});
}

function showContrattoWizard() {
  router.push("/contratto/wizard/contratto");
}

</script>

<style scoped>
</style>
