<template>
  <ModalDialog v-model:visible="show"
               :header="t('label.widget.carri.calendario.title')"
               style="width: 90%">
    <div class="grid">
      <div class="col-12">

          <FullCalendar :options="options"/>

          <Dialog v-model:visible="eventDialog"
                  :header="t('label.carro.scadenza.dettagli')"
                  :modal="true"
                  :style="{width: '450px'}"
                  class="z-5"
                  :closable="true">
            <div class="p-fluid">
              <div class="field">
                <label for="title">{{ t('label.widget.carri.calendario.dialog.title') }}</label>
                <InputText id="title" v-if="clickedEvent" v-model="changedEvent.title" required="true" :readonly="true"/>
              </div>
              <div class="field">
                <label for="start">{{ t('label.widget.carri.calendario.dialog.start') }}</label>
                <InputText id="start" v-if="clickedEvent" v-model="changedEvent.start" :readonly="true" />
              </div>
              <div class="field">
                <label for="end">{{ t('label.widget.carri.calendario.dialog.to') }}</label>
                <InputText id="end" v-if="clickedEvent" v-model="changedEvent.end" :readonly="true" />
              </div>

              <Button :label="t('action.vai-carri')" @click="toCarriPage"/>

            </div>
          </Dialog>

        </div>
      </div>
  </ModalDialog>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ModalDialog from "@/components/common/ModalDialog";
import EventService from "@/services/EventService";
import {SHOW_SCADENZE_CARRO_DIALOG} from "@/components/common/constants/Events";
import carroRestService from "@/services/CarroRestService";
import useText from "@/composable/useText";
import {useRouter} from "vue-router";
import useDateTime from "@/composable/useDateTime";
import {useI18n} from "vue-i18n";

defineProps({scadenze: {type: Array, required: false}});

const {t} = useI18n()
const {parseDate, dateFormat, now} = useDateTime();
const router = useRouter();
const {toLowercase} = useText();

const show = ref(false);


let eventDialog = ref(false);
let clickedEvent = ref(null);
const changedEvent = ref({
  title: '',
  start: null,
  end: '',
  allDay: null
});

const startDate = ref();
const endDate = ref();

const options = {
  initialView: 'dayGridMonth',
  plugins: [dayGridPlugin, interactionPlugin],
  initialDate: now(),
  headerToolbar: {
    left: 'prev,today,next',
    center: 'title',
    right: 'dayGridMonth'
  },
  editable: true,
  eventClick: (e) => {
    eventDialog.value = true;
    clickedEvent.value = e.event;
    changedEvent.value.title = clickedEvent.value.title;
    changedEvent.value.start = dateFormat(clickedEvent.value.start);
    changedEvent.value.end = dateFormat(clickedEvent.value.end);
  },
  datesRender: async (info) => {
    startDate.value = info.view.activeStart;
    endDate.value = info.view.activeEnd;
  },
  events: async (info, successCallback, failureCallback) => {
    try {
      const scadenze = await carroRestService.findScadenzeCarriByRange(info.start, info.end);
      let scadenzeCalendario = [];
      scadenzeCalendario = scadenze.map(s => {
        const title = s.tipoScadenza + " " + toLowercase(t('label.carro')) + " " + s.carro.matricola;
        return {id: s.id, title: title, start: parseDate(s.dataScadenza)}
      });
      successCallback(scadenzeCalendario);

    } catch (e) {
      failureCallback(e);
    }
  }
};


onMounted(() => {
  EventService.on(SHOW_SCADENZE_CARRO_DIALOG, onShow);
});

onUnmounted(() => {
  EventService.off(SHOW_SCADENZE_CARRO_DIALOG, onShow);
});


const onShow = () => {
  show.value = true;
}

const toCarriPage = () => {
  router.push('carri');
}
</script>

<style scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.fc-header-toolbar) {
    display: flex;
    flex-wrap: wrap;
  }
}

::v-deep(.fc .fc-view-container .fc-event-container .fc-event) {
  background-color: var(--primary-dark-color);
  border-color: var(--primary-dark-color);
  color: var(--primary-color-text)
}
</style>
