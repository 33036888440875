<template>
  <div class="fluid grid">

    <div class="col-12 md:col-8">
      <SoggettoRichiesteTable v-if="checkPermission([Roles.BROKER, Roles.BUYER, Roles.SELLER, Roles.CARICATORE,
      Roles.SCARICATORE, Roles.SURVEYOR, Roles.CUSTOM_BROKER, Roles.IMPRESA_FERROVIARIA])"/>
      <ContrattoBozzaTable v-if="checkPermission([Roles.BROKER])"/>
    </div>
    <div class="col-12 md:col-4" v-if="checkPermission([Roles.BROKER, Roles.BUYER, Roles.SELLER])">
      <UltimiContrattiWidget :num-items="5"/>
    </div>

    <DashboardViaggi v-if="checkPermission([Roles.SPEDIZIONIERE, Roles.EXECUTION_MANAGER])"/>

  </div>
</template>

<script setup>
import UltimiContrattiWidget from "@/components/dashboard/UltimiContrattiWidget";
import Roles from "@/components/common/constants/Roles";
import DashboardViaggi from "@/components/dashboard/DashboardViaggi";
import useAcl from "@/composable/useAcl";
import SoggettoRichiesteTable from "@/components/anagrafica/soggetto/richieste/SoggettoRichiesteTable";
import ContrattoBozzaTable from "@/components/contratto/ContrattoBozzaTable";

const {checkPermission} = useAcl();

</script>

<style scoped></style>
