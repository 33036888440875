<template>
  <div class="card schedule p-shadow-24">
    <div class="card-header">
      <div class="card-title">
        <h6 class="title">{{ t('label.widget.carri.scadenza-revisione', {days: DAYS_OFFSET}) }}</h6>
      </div>
      <div class="p-text-right">
        <Button :label="t('label.widget.carri.scadenze.show-calendar')" icon="pi pi-calendar"
                class="p-button-link calendar-box"
                @click="showScadenzeCarri"/>
      </div>
    </div>

    <ul v-for="scadenza in scadenze" :key="scadenza.dataScadenza">
      <li v-bind:class="{alert: isToday(scadenza.dataScadenza), warning: isTomorrow(scadenza.dataScadenza)}"
          class="scadenza" @click="toCarriPage">
        <div class="schedule-header">
          <h6>{{ scadenza.tipoScadenza }}</h6>
          <span style="font-size: 15px"> {{ dataScadenza(scadenza) }}</span>
        </div>
        <span>{{ scadenza.carro.matricola }}</span>
      </li>
    </ul>

    <ScadenzeCalendarDialog :scadenze="scadenze"/>

  </div>
</template>

<script setup>
import useDateTime from "@/composable/useDateTime";
import carroRestService from "@/services/CarroRestService";
import {onMounted, ref} from "vue";
import ScadenzeCalendarDialog from "@/components/dashboard/carro/ScadenzeCalendarDialog";
import EventService from "@/services/EventService";
import {SHOW_SCADENZE_CARRO_DIALOG} from "@/components/common/constants/Events";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

    const router = useRouter();
    const {t} = useI18n();
    const {dateFormat, isToday, isTomorrow} = useDateTime();

    const DAYS_OFFSET = 10;
    let scadenze = ref([]);

    onMounted(async () => {
      scadenze.value = await carroRestService.findScadenzeCarriFromToday(DAYS_OFFSET);
    })

    const showScadenzeCarri = () => {
      EventService.emit(SHOW_SCADENZE_CARRO_DIALOG);
    }

    const toCarriPage = () => {
      router.push('carri');
    }


    const dataScadenza = (scadenza) => {
      const dataScadenza = scadenza.dataScadenza;
      if (isToday(dataScadenza)) {
        return t('label.days.today');

      } else if (isTomorrow(dataScadenza)) {
        return t('label.days.tomorrow');

      } else {
        return dateFormat(scadenza.dataScadenza);
      }
    }

</script>

<style scoped>

::v-deep(.card-header) {
  font-size: 14px;
}


.title {
  font-size: 20px;
}

.calendar-box {
  font-size: 15px;
}

.scadenza {
  cursor: pointer;
}

.alert {
  background-color: rgba(255, 128, 128, 0.9);
}

.warning {
  background-color: rgba(255, 255, 204, 0.9);
}

</style>
